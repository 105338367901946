$spacer: 1rem; // 16px
$spacers: (
  0: 0,
  // 4
  1: $spacer * 0.25,
  // 8
  2: $spacer * 0.5,
  // 16
  3: $spacer,
  // 24
  4: $spacer * 1.5,
  // 36
  5: $spacer * 2.25,
  // 48
  6: $spacer * 3,
);
