$btn-border-radius: 4px;
$btn-border-radius-lg: 4px;

$input-border-radius-lg: 4px;
$input-border-radius: 4px;

// Close Button
$btn-close-width: 9px;
$btn-close-opacity: 0.9;
$btn-box-shadow: 0;

$btn-font-weight: 600;
$btn-padding-y: map-get($spacers, 2);
$btn-padding-x: map-get($spacers, 4);
