.vertical-rhythm-xs > *:not(:last-child) {
  margin-bottom: map-get($spacers, 2);
}

.vertical-rhythm-sm > *:not(:last-child) {
  margin-bottom: map-get($spacers, 3);
}

.vertical-rhythm > *:not(:last-child) {
  margin-bottom: map-get($spacers, 4);
}

.vertical-rhythm-lg > *:not(:last-child) {
  margin-bottom: map-get($spacers, 5);
}

@each $color, $value in $theme-colors {
  .custom-outline-#{$color}.form-control {
    &:focus {
      box-shadow: inset 0 1px 2px rgba($value, 0.075),
        0 0 0 0.25rem rgba($value, 0.25) !important;
    }
  }
}
