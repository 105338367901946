@import "./bootstrap-theme";

// 5. Additional customizations
@import "./links";
@import "./utils";

.modal-header .btn-close {
  height: 20px;
  width: 20px;
}

html {
  height: 100%;
}
