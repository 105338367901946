// Bootstrap customization: https://getbootstrap.com/docs/5.3/customize/sass/#importing

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "./fonts";
@import "./spacers";
@import "./colors";
@import "./borders";

/** Components */
@import "./badges";
@import "./buttons";
@import "./modals";
@import "./cards";

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
$theme-colors: map-merge($theme-colors, $custom-colors);
@import "./utilities.scss";

// background: var(--Bento-Cobalt, linear-gradient(90deg, rgba(39, 111, 191, 0.72) 0%, rgba(39, 111, 191, 0.52) 54.12%, rgba(39, 111, 191, 0.28) 100%));
@import "bootstrap/scss/bootstrap.scss";

@import "bootstrap-icons/font/bootstrap-icons.css";
