@import "@fontsource/source-sans-pro/400";
@import "@fontsource/source-sans-pro/600";
@import "@fontsource/source-sans-pro/700";

$font-family-sans-serif: "Source Sans Pro", sans-serif;

$headings-font-weight: 600;

.fw-semibold {
  font-weight: 600;
}

.large {
  font-size: 1.125rem;
}

// So far, there are only two header sizes in the designs:
$h1-font-size: 1rem * 1.75 !default;
$h2-font-size: 1rem * 1.5 !default;
$h3-font-size: 1rem * 1.25 !default;
$h4-font-size: 1rem * 1.125 !default;
$h5-font-size: 1rem * 1.08 !default;
